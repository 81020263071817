import { useState, useEffect } from "react";
import Pusher from "pusher-js";
import { format, subHours, addDays, startOfDay } from "date-fns";
import { Card, CircularProgress, Grid } from "@mui/material";
import SuiBadge from "components/SuiBadge";
import SuiBox from "components/SuiBox";
import { ResponseAlert } from "components/ResponseAlert";
import DataTable from "examples/Tables/DataTable";
import { WithdrawList } from "api/withdraw";

Pusher.logToConsole = false;
const pusherKey = process.env.REACT_APP_PUSHER_KEY;
const pusher = new Pusher(pusherKey, {
  cluster: "ap1",
});

const channel = pusher.subscribe("my-channel");

const columns = [
  { Header: "ยูสเซอร์", accessor: "member_username" },
  { Header: "จำนวน(บาท)", accessor: "amount" },
  { Header: "ทำรายการโดย", accessor: "username" },
  { Header: "วันที่/เวลา", accessor: "updatedAt" },
];

function WithdrawSuccess() {
  const [withdrawTableData, setWithdrawTableData] = useState({ columns, rows: [] });
  const [isLoading, setLoading] = useState(false);
  const [withdrawTotal, setWithdrawTotal] = useState(0);
  const startDate = format(new Date(), "yyyy-MM-dd");
  const endDate = format(new Date(), "yyyy-MM-dd");

  async function getWithdrawList() {
    setLoading(true);
    const createdAt = {};
    if (startDate) {
      createdAt.from = format(subHours(startOfDay(new Date(startDate)), 7), "yyyy-MM-dd HH:mm:ss");
    }
    if (endDate) {
      createdAt.to = format(
        subHours(startOfDay(addDays(new Date(endDate), 1)), 7),
        "yyyy-MM-dd HH:mm:ss"
      );
    }
    try {
      const response = await WithdrawList({ status_code: "success", createdAt });
      console.log("getWithdrawList-response", response);
      setLoading(false);
      const status = response?.data?.error;
      if (status === 0) {
        const withdList = response.data?.data?.result ?? [];
        const newWithdrawList = withdList.reduce((a, b) => {
          const newB = { ...b };

          newB.username = (
            <SuiBox>
              <SuiBadge variant="contained" color="success" badgeContent={b?.username} container />
            </SuiBox>
          );

          a.push(newB);
          return a;
        }, []);
        const sum = withdList.reduce((a, b) => {
          const total = a + parseFloat(b?.amount);
          return total;
        }, 0);
        setWithdrawTotal(sum);
        setWithdrawTableData({ columns, rows: newWithdrawList });
        return;
      }
      ResponseAlert(response);
    } catch (err) {
      setLoading(false);
      ResponseAlert(err.response);
    }
  }

  useEffect(() => {
    getWithdrawList();
  }, []);

  channel.bind(
    "withdrawpending",
    (data) => {
      console.log("pusher-withdrawSuccess-data", data);
      getWithdrawList();
    },
    channel.unbind("withdrawpending")
  );
  console.log("WithdrawSuccess-withdrawTotal", withdrawTotal);

  return (
    <SuiBox>
      <Grid customClass="overflow-x-scroll" container spacing={2}>
        <Grid item xs={12}>
          <Card>
            {isLoading ? (
              <SuiBox
                p={3}
                lineHeight={0}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress color="secondary" />
              </SuiBox>
            ) : (
              <SuiBox p={2}>
                <DataTable
                  table={withdrawTableData}
                  canSearch
                  noEndBorder
                  entriesPerPage={{
                    defaultValue: 5,
                    entries: [5, 10, 20, 50, 100],
                  }}
                />
              </SuiBox>
            )}
          </Card>
        </Grid>
      </Grid>
    </SuiBox>
  );
}

export default WithdrawSuccess;
