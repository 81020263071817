/* eslint-disable */
import { useMemo, useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { CircularProgress } from "@mui/material";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";
import SuiInput from "components/SuiInput";
import SuiBadge from "components/SuiBadge";
import SuiPagination from "components/SuiPagination";
import { formatter } from "utils/validations";

// Soft UI Dashboard PRO React example components
import DataTableHeadCell from "./DataTableHeadCell";
import DataTableBodyCell from "./DataTableBodyCell";

function MemberDataTable({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  onClickSetting,
  onClickEdit,
  onClickTransactionHistory,
  onClickTransferHistory,
  onClickOpenFriend,
  onClickResetPassword,
}) {
  const { defaultValue, entries } = entriesPerPage;
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const [openMenu, setOpenMenu] = useState(null);
  const [menuItem, setMenuItem] = useState(null);

  console.log("MemberDataTable-openMenu", openMenu);

  let user = localStorage.getItem("user");
  user = JSON.parse(user);

  console.log("MemberDataTable-user", user);
  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = ({ value }) => setPageSize(value);

  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <SuiPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </SuiPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  function handleMemberSettings(row) {
    const item = row?.original;
    setOpenMenu(null);
    onClickSetting(item);
  }

  function handleEditMember(row) {
    const item = row?.original;
    setOpenMenu(null);
    onClickEdit(item);
  }

  function handleOpenTransac(row) {
    const item = row?.original;
    setOpenMenu(null);
    onClickTransactionHistory(item);
  }

  function handleOpenTranf(row) {
    const item = row?.original;
    setOpenMenu(null);
    onClickTransferHistory(item);
  }

  function handleOpenFriend(row) {
    console.log("handleOpenFriend-row", row);
    const item = row?.original;
    console.log("handleOpenFriend-item", item);
    setOpenMenu(null);
    onClickOpenFriend(item);
  }

  function handleResetpassword(row) {
    const item = row?.original;
    setOpenMenu(null);
    onClickResetPassword(item);
  }

  function renderMenu() {
    return (
      <Menu
        anchorEl={openMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={openMenu}
        onClose={() => {
          setOpenMenu(null);
          setMenuItem(null);
        }}
        keepMounted
      >
        <MenuItem onClick={() => handleOpenTransac(menuItem)}>ประวัติฝาก/ถอน</MenuItem>
        <MenuItem onClick={() => handleOpenTranf(menuItem)}>ประวัติโยกเครดิต</MenuItem>
        <MenuItem onClick={() => handleOpenFriend(menuItem)}>
          เพื่อน: {menuItem?.original?.total_friend ?? "0"} คน
        </MenuItem>
        {user?.role_code !== "marketing" && (
          <MenuItem onClick={() => handleResetpassword(menuItem)}>รีเซ็ตรหัสผ่าน</MenuItem>
        )}

        {user?.role_code === "admin" ||
          (user?.role_code === "owner" && (
            <MenuItem onClick={() => handleEditMember(menuItem)}>แก้ไขข้อมูล</MenuItem>
          ))}
        {user?.role_code === "admin" ||
          (user?.role_code === "owner" && (
            <MenuItem onClick={() => handleMemberSettings(menuItem)}>ตั้งค่า</MenuItem>
          ))}
      </Menu>
    );
  }

  return (
    <TableContainer className="shadow-none">
      {entriesPerPage || canSearch ? (
        <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          {entriesPerPage && (
            <SuiBox display="flex" alignItems="center">
              <SuiSelect
                defaultValue={{ value: defaultValue, label: defaultValue }}
                options={entries.map((entry) => ({ value: entry, label: entry }))}
                onChange={setEntriesPerPage}
                size="small"
              />
              <SuiTypography variant="caption" textColor="secondary">
                &nbsp;&nbsp;entries per page
              </SuiTypography>
            </SuiBox>
          )}
          {canSearch && (
            <SuiBox width="12rem" ml="auto">
              <SuiInput
                placeholder="Search..."
                value={search}
                onChange={({ currentTarget }) => {
                  setSearch(search);
                  onSearchChange(currentTarget.value);
                }}
              />
            </SuiBox>
          )}
        </SuiBox>
      ) : null}
      <Table {...getTableProps()}>
        <SuiBox component="thead">
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <DataTableHeadCell
                  {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}
                  sorted={setSortedValue(column)}
                >
                  {column.render("Header")}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </SuiBox>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, key) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  const summ = row?.original?.summary;
                  let badgeColor = "secondary";
                  if (summ < 0) {
                    badgeColor = "error";
                  }
                  if (summ > 0) {
                    badgeColor = "success";
                  }
                  if (cell?.column?.id === "sum_deposit") {
                    if (row?.original?.sum_deposit === "isLoading") {
                      return (
                        <DataTableBodyCell
                          noBorder={noEndBorder && rows.length - 1 === key}
                          align={cell.column.align ? cell.column.align : "left"}
                          {...cell.getCellProps()}
                        >
                          <SuiBox>
                            <SuiBox ml="auto" color="secondary" pr={3} lineHeight={0}>
                              <CircularProgress size="1rem" color="secondary" />
                            </SuiBox>
                          </SuiBox>
                        </DataTableBodyCell>
                      );
                    } else {
                      return (
                        <DataTableBodyCell
                          noBorder={noEndBorder && rows.length - 1 === key}
                          align={cell.column.align ? cell.column.align : "left"}
                          {...cell.getCellProps()}
                        >
                          <SuiBox>
                            <SuiBox ml="auto" color="secondary" pr={3} lineHeight={0}>
                              <SuiTypography
                                component="label"
                                variant="caption"
                                textColor="success"
                              >
                                {formatter.format(row?.original?.sum_deposit ?? 0).toString()}
                              </SuiTypography>
                            </SuiBox>
                          </SuiBox>
                        </DataTableBodyCell>
                      );
                    }
                  }

                  if (cell?.column?.id === "sum_withdraw") {
                    if (row?.original?.sum_withdraw === "isLoading") {
                      return (
                        <DataTableBodyCell
                          noBorder={noEndBorder && rows.length - 1 === key}
                          align={cell.column.align ? cell.column.align : "left"}
                          {...cell.getCellProps()}
                        >
                          <SuiBox>
                            <SuiBox ml="auto" color="secondary" pr={3} lineHeight={0}>
                              <CircularProgress size="1rem" color="secondary" />
                            </SuiBox>
                          </SuiBox>
                        </DataTableBodyCell>
                      );
                    } else {
                      return (
                        <DataTableBodyCell
                          noBorder={noEndBorder && rows.length - 1 === key}
                          align={cell.column.align ? cell.column.align : "left"}
                          {...cell.getCellProps()}
                        >
                          <SuiBox>
                            <SuiBox ml="auto" color="secondary" pr={3} lineHeight={0}>
                              <SuiTypography component="label" variant="caption" textColor="error">
                                {formatter.format(row?.original?.sum_withdraw ?? 0).toString()}
                              </SuiTypography>
                            </SuiBox>
                          </SuiBox>
                        </DataTableBodyCell>
                      );
                    }
                  }

                  if (cell?.column?.id === "summary") {
                    if (row?.original?.sum_deposit === "isLoading") {
                      return (
                        <DataTableBodyCell
                          noBorder={noEndBorder && rows.length - 1 === key}
                          align={cell.column.align ? cell.column.align : "left"}
                          {...cell.getCellProps()}
                        >
                          <SuiBox>
                            <SuiBox ml="auto" color="secondary" pr={3} lineHeight={0}>
                              <CircularProgress size="1rem" color="secondary" />
                            </SuiBox>
                          </SuiBox>
                        </DataTableBodyCell>
                      );
                    } else {
                      return (
                        <DataTableBodyCell
                          noBorder={noEndBorder && rows.length - 1 === key}
                          align={cell.column.align ? cell.column.align : "left"}
                          {...cell.getCellProps()}
                        >
                          <SuiBox>
                            <SuiBox ml="auto" color="success" pr={3} lineHeight={0}>
                              <SuiBadge
                                color={badgeColor}
                                size="extra-small"
                                circular
                                variant="contained"
                                badgeContent={
                                  <SuiTypography
                                    component="label"
                                    variant="caption"
                                    textColor="dark"
                                  >
                                    {formatter.format(row?.original?.summary ?? 0).toString()}
                                  </SuiTypography>
                                }
                              />
                            </SuiBox>
                          </SuiBox>
                        </DataTableBodyCell>
                      );
                    }
                  }

                  if (cell?.column?.id === "total_friend") {
                    if (row?.original?.total_friend === "isLoading") {
                      return (
                        <DataTableBodyCell
                          noBorder={noEndBorder && rows.length - 1 === key}
                          align={cell.column.align ? cell.column.align : "left"}
                          {...cell.getCellProps()}
                        >
                          <SuiBox>
                            <SuiBox ml="auto" color="secondary" pr={3} lineHeight={0}>
                              <CircularProgress size="1rem" color="secondary" />
                            </SuiBox>
                          </SuiBox>
                        </DataTableBodyCell>
                      );
                    } else {
                      return (
                        <DataTableBodyCell
                          noBorder={noEndBorder && rows.length - 1 === key}
                          align={cell.column.align ? cell.column.align : "left"}
                          {...cell.getCellProps()}
                        >
                          <SuiBox>
                            <SuiBox ml="auto" color="secondary" pr={3} lineHeight={0}>
                              <SuiTypography component="label" variant="caption">
                                {row?.original?.total_friend}
                              </SuiTypography>
                            </SuiBox>
                          </SuiBox>
                        </DataTableBodyCell>
                      );
                    }
                  }

                  return (
                    <DataTableBodyCell
                      noBorder={noEndBorder && rows.length - 1 === key}
                      align={cell.column.align ? cell.column.align : "left"}
                      {...cell.getCellProps()}
                    >
                      {cell?.column?.id === "action" ? (
                        <SuiBox>
                          <SuiBox ml="auto" color="secondary" pr={3} lineHeight={0}>
                            <Icon
                              className="material-ui-icons cursor-pointer"
                              fontSize="default"
                              onClick={(event) => {
                                setOpenMenu(event.currentTarget);
                                setMenuItem(row);
                              }}
                            >
                              more_horiz
                            </Icon>
                          </SuiBox>
                          {renderMenu()}
                        </SuiBox>
                      ) : (
                        <>{cell.render("Cell")}</>
                      )}
                    </DataTableBodyCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <SuiBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {showTotalEntries && (
          <SuiBox mb={{ xs: 3, sm: 0 }}>
            <SuiTypography variant="button" textColor="secondary" fontWeight="regular">
              Showing {entriesStart} to {entriesEnd} of {rows.length} entries
            </SuiTypography>
          </SuiBox>
        )}
        {pageOptions.length > 1 && (
          <SuiPagination
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
          >
            {canPreviousPage && (
              <SuiPagination item onClick={() => previousPage()}>
                <Icon className=" bold">chevron_left</Icon>
              </SuiPagination>
            )}
            {renderPagination.length > 6 ? (
              <SuiBox width="5rem" mx={1}>
                <SuiInput
                  inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                  value={customizedPageOptions[pageIndex]}
                  onChange={(handleInputPagination, handleInputPaginationValue)}
                />
              </SuiBox>
            ) : (
              renderPagination
            )}
            {canNextPage && (
              <SuiPagination item onClick={() => nextPage()}>
                <Icon className=" font-bold">chevron_right</Icon>
              </SuiPagination>
            )}
          </SuiPagination>
        )}
      </SuiBox>
    </TableContainer>
  );
}

MemberDataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
  onClickSetting: () => {},
  onClickEdit: () => {},
  onClickTransactionHistory: () => {},
  onClickTransferHistory: () => {},
  onClickOpenFriend: () => {},
  onClickResetPassword: () => {},
};

MemberDataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  onClickSetting: PropTypes.func,
  onClickEdit: PropTypes.func,
  onClickTransactionHistory: PropTypes.func,
  onClickTransferHistory: PropTypes.func,
  onClickOpenFriend: PropTypes.func,
  onClickResetPassword: PropTypes.func,
};

export default MemberDataTable;
