/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { useState } from "react";

// import Pusher from "pusher-js";
/* eslint-disable */
// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DepositSuccess from "./deposit-success";
import SMSAlert from "./newdepositwidget/sms-alert";
import WithdrawPending from "./withdraw/withdraw-pending";
import WithdrawSuccess from "./withdraw/withdraw-success";

function Default(props) {
  console.log("dashboard-props", props);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <SuiBox display="flex" height="100%" width="100%">
        <Grid container spacing={2}>
          <Grid item xs={12} xl={6} sx={{ display: "flex", flex: 1, maxHeight: "50%" }}>
            <SuiBox display="flex" flex={1} flexDirection="column">
              <SuiTypography variant="h6" textTransform="capitalize" fontWeight="bold">
                รายการเงินเข้า
              </SuiTypography>
              <SuiBox>
                <SMSAlert />
              </SuiBox>
            </SuiBox>
          </Grid>
          <Grid item xs={12} xl={6} sx={{ display: "flex", flex: 1, maxHeight: "50%" }}>
            <SuiBox display="flex" flex={1} flexDirection="column">
              <SuiTypography variant="h6" textTransform="capitalize" fontWeight="bold">
                รายการรอถอน
              </SuiTypography>
              <SuiBox>
                <WithdrawPending />
              </SuiBox>
            </SuiBox>
          </Grid>
          <Grid
            item
            xs={12}
            xl={6}
            sx={{ display: "flex", flex: 1, maxHeight: "50%", marginTop: "20px" }}
          >
            <SuiBox display="flex" flex={1} flexDirection="column">
              <SuiTypography variant="h6" textTransform="capitalize" fontWeight="bold">
                ฝากเงินสำเร็จ
              </SuiTypography>
              <SuiBox>
                <DepositSuccess />
              </SuiBox>
            </SuiBox>
          </Grid>
          <Grid
            item
            xs={12}
            xl={6}
            sx={{ display: "flex", flex: 1, maxHeight: "50%", marginTop: "20px" }}
          >
            <SuiBox display="flex" flex={1} flexDirection="column">
              <SuiTypography variant="h6" textTransform="capitalize" fontWeight="bold">
                ถอนเงินสำเร็จ
              </SuiTypography>
              <SuiBox>
                <WithdrawSuccess />
              </SuiBox>
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </DashboardLayout>
  );
}

export default Default;
