/* eslint-disable */
import SuiBox from "components/SuiBox";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import DataTable from "examples/Tables/DataTable";
import {
  Card,
  Divider,
  FormControlLabel,
  Grid,
  Icon,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import { MemberCashback } from "api/member";
import { ResponseAlert } from "components/ResponseAlert";
import SuiBadge from "components/SuiBadge";
import UploadExcel from "./upload-execl";
import { ImportSearchFriend, ImportMemberCashback } from "api/member";
import { formatter } from "utils/validations";
import ReactExport from "react-export-excel";
import ExpandableTable from "react-exp-table";

const headerColumn = [
  { Header: "#", accessor: "status_code", width: "5%" },
  { Header: "ยูสเซอร์", accessor: "username" },
  { Header: "ยอดรวม", accessor: "summary" },
  { Header: "ยอดคืน", accessor: "credit" },
  { Header: "เพื่อนลำดับที่_1", accessor: "friend_ref_1[friend_username]" },
  { Header: "ยอดแนะเพื่อน_1", accessor: "friend_ref_1[friend_credit]" },
  { Header: "เพื่อนลำดับที่_2", accessor: "friend_ref_2[friend_username]" },
  { Header: "ยอดแนะเพื่อน_2", accessor: "friend_ref_2[friend_credit]" },
];

const columns = [
  {
    title: "ยูสเซอร์",
    key: "username",
  },
  {
    title: "ยอดรวม",
    key: "summary",
  },
  {
    title: "ยอดคืน",
    key: "credit",
  },
  {
    title: "สถานะ",
    key: "status_icon",
  },
];

function ImportCashback() {
  const [data, setData] = useState({ columns: headerColumn, rows: [] });
  const [countMember, setCountMember] = useState(0);
  const [sumCashback, setSumCashback] = useState(0);
  const [sumFriendRef, setSumFriendRef] = useState([]);

  const [excel, setExcel] = useState();
  const [keys, setKeys] = useState();

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  async function handleDownloadTemplate() {
    const newExcel = [{ username: "0000", summary: "500000", credit: "50" }];
    const headerKeys = newExcel && newExcel.length > 0 ? Object.keys(newExcel[0]) : [];
    setExcel(newExcel);
    setKeys(headerKeys);
  }

  function handleChangeData(arr) {
    console.log("ImportCashback - handleChangeData", arr);
    const cashSum = arr.reduce((a, b) => {
      let c = b?.credit;
      if (c < 0) {
        c = c * -1;
      }
      const value = a + c;
      return value;
    }, 0);
    setSumCashback(cashSum.toFixed(2));
    setData({ columns: headerColumn, rows: arr });
    setCountMember(arr.length);
    searchFriendRef(arr);
  }

  async function searchFriendRef(arr) {
    Swal.fire({
      title: "กำลังโหลด ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const res = await ImportSearchFriend(arr);
      const resData = res?.data?.data;
      console.log("ImportCashback - searchFriendRef - resData", resData);
      const newData = resData.reduce((a, item) => {
        const newItem = { ...item };
        if (item?.status_code === "success") {
          newItem.status_icon = (
            <SuiButton variant="outlined" buttonColor="success" size="small" iconOnly circular>
              <Icon className=" font-bold">done</Icon>
            </SuiButton>
          );
        }

        a.push(newItem);
        return a;
      }, []);

      const sumFriendRef = resData.reduce((a, item) => {
        if (item?.child && item?.child.length > 0) {
          item?.child.forEach(async function (child, index) {
            console.log("ImportCashback - searchFriendRef - sumFriendRef - index", index);
            console.log("ImportCashback - searchFriendRef - sumFriendRef - child", child);
            console.log("ImportCashback - searchFriendRef - sumFriendRef - a", a);
            const sum = a[index] && a[index] > 0 ? a[index] : 0;
            const summary = Number(sum) + Number(child?.credit);

            console.log("ImportCashback - searchFriendRef - sumFriendRef - summary", summary);
            if (a && a.length > 0) {
              a[index] = summary;
            } else {
              a.push(summary);
            }
          });
        }
        return a;
      }, []);

      console.log("ImportCashback - searchFriendRef - sumFriendRef", sumFriendRef);
      setData({ columns: headerColumn, rows: newData });
      setSumFriendRef(sumFriendRef);
      Swal.close();
    } catch (error) {
      Swal.close();
    }
  }

  async function handleSubmit() {
    console.log("handleSubmit-data", data);
    Swal.fire({
      title: "กำลังคืนยอดเสีย ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const cashBackData = data?.rows;
    try {
      const importM = await ImportMemberCashback(cashBackData);
      const resData = importM?.data?.data;
      const newData = resData.reduce((a, item) => {
        const newItem = { ...item };
        if (item?.status_code === "success") {
          newItem.status_icon = (
            <SuiButton variant="outlined" buttonColor="success" size="small" iconOnly circular>
              <Icon className=" font-bold">done</Icon>
            </SuiButton>
          );
        }

        if (item?.child) {
          item?.child.forEach(function (child, i) {
            const newChild = { ...child };
            newChild.status_icon = (
              <SuiButton variant="outlined" buttonColor="success" size="small" iconOnly circular>
                <Icon className=" font-bold">done</Icon>
              </SuiButton>
            );
            newItem.child[i] = newChild;
          });
        }

        a.push(newItem);
        return a;
      }, []);
      setData({ columns: headerColumn, rows: newData });
      Swal.close();
    } catch (error) {
      Swal.close();
    }
  }

  useEffect(() => {
    handleDownloadTemplate();
  }, []);

  return (
    <SuiBox p={3} lineHeight={1}>
      <SuiBox>
        <SuiBox display="flex">
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            อัพโหลดไฟล์
          </SuiTypography>
          {excel && excel.length > 0 && keys && keys.length > 0 && (
            <SuiBox ml={2}>
              <ExcelFile
                filename={`cashback_template`}
                element={
                  <SuiBox display="flex">
                    <Typography variant="caption" sx={{ color: "#627594", fontStyle: "italic" }}>
                      {"**ตัวอย่างไฟล์   "}{" "}
                      <Typography
                        variant="caption"
                        sx={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        {"   ดาวน์โหลด"}
                      </Typography>
                    </Typography>
                  </SuiBox>
                }
              >
                <ExcelSheet data={excel} name="cashback_template">
                  {keys.map((k) => (
                    <ExcelColumn label={k} value={k} />
                  ))}
                </ExcelSheet>
              </ExcelFile>
            </SuiBox>
          )}
        </SuiBox>
        <Divider />
        <SuiBox display="flex" justifyContent="space-between">
          <SuiBox>
            <UploadExcel onChangeData={(arr) => handleChangeData(arr)} />
          </SuiBox>
          <SuiBox>
            <SuiButton
              variant="gradient"
              buttonColor="info"
              size="small"
              circular
              onClick={() => handleSubmit()}
            >
              คืนยอดเสีย
            </SuiButton>
          </SuiBox>
        </SuiBox>
      </SuiBox>
      <Divider />
      <SuiBox>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SuiBox display="flex" flexDirection="column" alignItems="center">
              <SuiBadge
                color="info"
                badgeContent={`จำนวนสมาชิก`}
                size="small"
                circular
                variant="contained"
              />
              <SuiTypography mt={1} variant="h5" textColor="dark">
                {countMember}
              </SuiTypography>
            </SuiBox>
          </Grid>
          <Grid item xs={6}>
            <SuiBox display="flex" flexDirection="column" alignItems="center">
              <SuiBadge
                color="info"
                badgeContent={`ยอดรวมสมาชิก`}
                size="small"
                circular
                variant="contained"
              />
              <SuiTypography mt={1} variant="h5" textColor="dark">
                {formatter.format(sumCashback).toString()}
              </SuiTypography>
            </SuiBox>
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={2}>
          {sumFriendRef &&
            sumFriendRef.length > 0 &&
            sumFriendRef.map((item, index) => (
              <Grid item xs={12 / sumFriendRef.length}>
                <SuiBox display="flex" flexDirection="column" alignItems="center">
                  <SuiBadge
                    color="secondary"
                    badgeContent={`รวมยอดเพื่อนลำดับที่ ${index + 1}`}
                    size="small"
                    circular
                    variant="contained"
                  />
                  <SuiTypography mt={1} variant="h5" textColor="dark">
                    {formatter.format(item).toString()}
                  </SuiTypography>
                </SuiBox>
              </Grid>
            ))}
        </Grid>
      </SuiBox>
      <ExpandableTable columns={columns} data={data?.rows} hideCollapseExpandButtons={false} />
    </SuiBox>
  );
}

export default ImportCashback;
