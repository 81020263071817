/* eslint-disable */
import SuiBox from "components/SuiBox";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import DataTable from "examples/Tables/DataTable";
import {
  Card,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Grid,
  Icon,
  Typography,
} from "@mui/material";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import { MemberCashback } from "api/member";
import { ResponseAlert } from "components/ResponseAlert";
import SuiBadge from "components/SuiBadge";
import { ImportSearchFriend, ImportMemberCashback } from "api/member";
import { formatter } from "utils/validations";
import ReactExport from "react-export-excel";
import ExpandableTable from "react-exp-table";
import UploadExcel from "layouts/cashback/upload-execl";
import { ImportMemberCredit } from "api/member";

const headerColumn = [
  { Header: "ยูสเซอร์", accessor: "username", width: "10%" },
  { Header: "จำนวน", accessor: "credit", width: "20%" },
  { Header: "remark", accessor: "remark", width: "20%" },
  { Header: "Before", accessor: "before_balance", width: "20%" },
  { Header: "Current", accessor: "current_balance", width: "20%" },
  { Header: "สถานะ", accessor: "status_icon", width: "10%" },
];

const columns = [
  {
    title: "ยูสเซอร์",
    key: "username",
  },
  {
    title: "จำนวน",
    key: "credit",
  },
  {
    title: "remark",
    key: "remark",
  },
  {
    title: "Before",
    key: "before_balance",
  },
  {
    title: "Current",
    key: "current_balance",
  },

  {
    title: "สถานะ",
    key: "status_icon",
  },
];

function ImportCredit() {
  const [data, setData] = useState({ columns: headerColumn, rows: [] });
  const [countMember, setCountMember] = useState(0);
  const [sumCredit, setSumCredit] = useState(0);
  const [type, setType] = useState("");

  const [excel, setExcel] = useState();
  const [keys, setKeys] = useState();

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  async function handleDownloadTemplate() {
    const newExcel = [{ username: "0000", credit: "50", remark: "โปรโมชั่น_สงกรานต์" }];
    const headerKeys = newExcel && newExcel.length > 0 ? Object.keys(newExcel[0]) : [];
    setExcel(newExcel);
    setKeys(headerKeys);
  }

  function handleChangeData(arr) {
    console.log("ImportCashback - handleChangeData", arr);
    const creditSum = arr.reduce((a, b) => {
      let c = b?.credit;
      if (c < 0) {
        c = c * -1;
      }
      const value = a + c;
      return value;
    }, 0);
    setSumCredit(creditSum);
    setData({ columns: headerColumn, rows: arr });
    setCountMember(arr.length);
  }

  async function handleSubmit() {
    console.log("handleSubmit-data", data);
    if (!type) {
      Swal.fire("", "กรุณาเลือกเพิ่มหรือลดเครดิต", "warning");
      return;
    }
    Swal.fire({
      title: "กำลังคืนยอดเสีย ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const creditData = { type: type, data: data?.rows };
    try {
      const importM = await ImportMemberCredit(creditData);
      const resData = importM?.data?.data;
      const newData = resData.reduce((a, item) => {
        const newItem = { ...item };
        if (item?.status_code === "success") {
          newItem.status_icon = (
            <SuiButton variant="outlined" buttonColor="success" size="small" iconOnly circular>
              <Icon className=" font-bold">done</Icon>
            </SuiButton>
          );
        }

        a.push(newItem);
        return a;
      }, []);
      setData({ columns: headerColumn, rows: newData });
      Swal.close();
    } catch (error) {
      Swal.close();
    }
  }

  useEffect(() => {
    handleDownloadTemplate();
  }, []);

  return (
    <SuiBox p={3} lineHeight={1}>
      <SuiBox>
        <SuiBox display="flex">
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            อัพโหลดไฟล์
          </SuiTypography>
          {excel && excel.length > 0 && keys && keys.length > 0 && (
            <SuiBox ml={2}>
              <ExcelFile
                filename={`import_credit_template`}
                element={
                  <SuiBox display="flex">
                    <Typography variant="caption" sx={{ color: "#627594", fontStyle: "italic" }}>
                      {"**ตัวอย่างไฟล์   "}{" "}
                      <Typography
                        variant="caption"
                        sx={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        {"   ดาวน์โหลด"}
                      </Typography>
                    </Typography>
                  </SuiBox>
                }
              >
                <ExcelSheet data={excel} name="import_credit_template">
                  {keys.map((k) => (
                    <ExcelColumn label={k} value={k} />
                  ))}
                </ExcelSheet>
              </ExcelFile>
            </SuiBox>
          )}
        </SuiBox>
        <Divider />
        <SuiBox display="flex" justifyContent="space-between">
          <SuiBox>
            <UploadExcel onChangeData={(arr) => handleChangeData(arr)} />
          </SuiBox>
          <SuiBox>
            <SuiButton
              variant="gradient"
              buttonColor="info"
              size="small"
              circular
              onClick={() => handleSubmit()}
            >
              ทำรายการ
            </SuiButton>
          </SuiBox>
        </SuiBox>
      </SuiBox>
      <Divider />
      <SuiBox>
        <SuiBox>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={type}
            onChange={(t) => setType(t.target.value)}
          >
            <SuiBox display="flex">
              <FormControlLabel value="add" control={<Radio />} label="เพิ่มเครดิต" />
              <FormControlLabel
                sx={{ marginLeft: 1 }}
                value="remove"
                control={<Radio />}
                label="ลดเครดิต"
              />
            </SuiBox>
          </RadioGroup>
        </SuiBox>
      </SuiBox>
      <Divider />
      <SuiBox>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SuiBox display="flex" flexDirection="column" alignItems="center">
              <SuiBadge
                color="info"
                badgeContent={`จำนวนสมาชิก`}
                size="small"
                circular
                variant="contained"
              />
              <SuiTypography mt={1} variant="h5" textColor="dark">
                {countMember}
              </SuiTypography>
            </SuiBox>
          </Grid>
          <Grid item xs={6}>
            <SuiBox display="flex" flexDirection="column" alignItems="center">
              <SuiBadge
                color="info"
                badgeContent={`รวมทั้งหมด`}
                size="small"
                circular
                variant="contained"
              />
              <SuiTypography mt={1} variant="h5" textColor="dark">
                {formatter.format(sumCredit).toString()}
              </SuiTypography>
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
      <DataTable table={data} />
    </SuiBox>
  );
}

export default ImportCredit;
