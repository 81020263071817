import axios from "./index";

const base = "member";
const accessToken = window.localStorage.getItem("accessToken");
axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

// export const MemberList = (data) => axios.post(`${base}/memberlist`, data);
// export const FindMember = (data) => axios.post(`${base}/findmember`, data);

export const CreateMember = async (data) => {
  try {
    const response = await axios.post(`${base}/create`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const MemberList = async (data) => {
  try {
    const response = await axios.post(`${base}/memberlist`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const MemberRef = async (data) => {
  try {
    const response = await axios.post(`${base}/member_ref`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const MemberSummary = async (data) => {
  try {
    const response = await axios.post(`${base}/member_summary`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const ImportSearchFriend = async (data) => {
  try {
    const response = await axios.post(`${base}/import/search_friend`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const ImportMemberCashback = async (data) => {
  try {
    const response = await axios.post(`${base}/import/cashback`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const ImportMemberCredit = async (data) => {
  try {
    const response = await axios.post(`${base}/import/credit`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const FindMember = async (data) => {
  try {
    const response = await axios.post(`${base}/findmember`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const CheckBalance = async (data) => {
  try {
    const response = await axios.post(`${base}/checkbalance`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const transactionCredit = async (data) => {
  try {
    const response = await axios.post(`${base}/transaction`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const transactionHistory = async (data) => {
  try {
    const response = await axios.post(`${base}/history`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateMember = async (data) => {
  try {
    const response = await axios.post(`${base}/update`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const changePassword = async (data) => {
  try {
    const response = await axios.post(`${base}/changePassword`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const lockMember = async (data) => {
  try {
    const response = await axios.post(`${base}/lock`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const transferCreditHistory = async (data) => {
  try {
    const response = await axios.post(`${base}/transfercredit`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const MemberCashback = async (data) => {
  try {
    const response = await axios.post(`${base}/cashback`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const MemberFriends = async (data) => {
  try {
    const response = await axios.post(`${base}/friends`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const MemberBonusWheel = async (data) => {
  try {
    const response = await axios.post(`${base}/bonus/wheel`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};
