/* eslint-disable */
import { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { read, utils } from "xlsx";
import SuiButton from "components/SuiButton";

function UploadExcel({ onChangeData }) {
  const [file, setFile] = useState();
  const [fileData, setFileData] = useState();

  const hiddenFileInput = useRef(null);

  const handleOnChange = (e) => {
    const ff = e.target.files;
    if (ff.length) {
      const fileInfo = ff[0];
      setFile(fileInfo);
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;

        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          setFileData(rows);
          onChangeData(rows);
        }
      };
      reader.readAsArrayBuffer(fileInfo);
    }
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click(); // ADDED
  };

  useEffect(() => {}, []);

  console.log("fileData", fileData);
  return (
    <>
      <SuiBox display="inline-block" flexDirection="column">
        <SuiBox>
          <div className="input-group">
            <SuiButton
              onClick={handleClick}
              variant="gradient"
              buttonColor="dark"
              size="small"
              circular
            >
              เลือกไฟล์
            </SuiButton>
            <input
              ref={hiddenFileInput}
              id="file"
              style={{ display: "none" }}
              type="file"
              onChange={handleOnChange}
            />
          </div>
          <SuiTypography component="label" variant="caption">
            {file?.name}
          </SuiTypography>
        </SuiBox>
      </SuiBox>
    </>
  );
}

UploadExcel.defaultProps = {
  onChangeData: () => {},
};

UploadExcel.propTypes = {
  onChangeData: PropTypes.func,
};

export default UploadExcel;
