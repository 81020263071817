import { useState } from "react";

import Swal from "sweetalert2";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import { Divider, FormControlLabel, Radio, RadioGroup } from "@mui/material";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { ResponseAlert } from "components/ResponseAlert";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";

import { transactionCredit } from "api/member";
import { verifyCurrency } from "utils/validations";
import MemberCheck from "./member-check";
import contrast from "../../assets/png/contrast.png";

const optios = [
  { label: "คืนยอดเสีย", value: "cashback", isInput: false },
  {
    label: "โปรโมชั่น",
    value: "promotion",
    isInput: false,
  },
  { label: "อื่นๆ (โปรดระบุ)", value: "other", isInput: true },
];

function CreditForm() {
  const [member, setMember] = useState();
  const [balance, setBalance] = useState();
  const [value, setValue] = useState("");
  const [error, setError] = useState();
  const [type, setType] = useState("");
  const [reason, setReason] = useState();
  const [remark, setRemark] = useState();
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  function handleChange(v) {
    setRemark(v);
  }

  const handleSetValue = (event) => {
    setError(undefined);
    const v = event.target.value;
    console.log("handleSetAmount-v", v);
    if (!verifyCurrency(v)) {
      setError("กรอกเฉพาะตัวเลข, จำนวนเต็ม, จำนวนทศนิยม 2 หลัก เท่านั้น เช่น 100, 100.50");
    }
    setValue(v);
  };

  const handleSetMemberBalance = (row) => {
    setMember(row?.member);
    setBalance(row?.balance);
  };

  const handleConfirm = async () => {
    const payload = {
      member_uid: member?.member_uid,
      amount: value,
      type,
      remark: remark ?? reason?.value,
    };
    try {
      const response = await transactionCredit(payload);
      if (response?.data?.error === 0) {
        const data = response?.data?.data;
        Swal.fire({
          title: "ทำรายการสำเร็จ",
          icon: "success",
          html: `ยูสเซอร์: <b>${member?.username}</b><br/>จำนวน: <b>${formatter
            .format(value)
            .toString()}</b><br/>เครดิตปัจจุบัน: <b>${formatter
            .format(data?.credit_balance)
            .toString()}</b>`,
          preConfirm: () => {
            setMember();
            setBalance();
            setValue();
            setReason();
            setRemark();
            setType("");
          },
        });
        return;
      }
      ResponseAlert(response);
    } catch (e) {
      ResponseAlert(e?.response);
    }
  };

  const handleCheck = () => {
    if (value <= 0) {
      Swal.fire("", "กรุณาใส่จำนวนเงิน", "warning");
      return;
    }

    if (type === "remove" && parseFloat(value) > parseFloat(balance)) {
      Swal.fire("", "ยอดเงินไม่เพียงพอสำหรับลดเครดิต", "warning");
      return;
    }

    if (!type) {
      Swal.fire("", "กรุณาเลือกเพิ่มหรือลด", "warning");
      return;
    }

    if (reason?.value === "other" && !remark) {
      Swal.fire("", "กรุณาระบบเหตุในการเพิ่ม/ลดเครดิต", "warning");
      return;
    }
    if (!member?.member_uid || !value) {
      Swal.fire(
        "",
        "ข้อมูลไม่ถูกต้องหรือไม่ครบถ้วน กรุณาตรวจสอบยูสเซอร์ และ จำนวน ที่ต้องการทำรายกร",
        "warning"
      );
    } else {
      const newSwal = Swal.mixin({
        customClass: {
          confirmButton: "button button-info button-flex",
          cancelButton: "button button-error",
        },
        buttonsStyling: false,
      });
      let typeLabel = "";
      if (type === "add") {
        typeLabel = "เพิ่ม";
      }
      if (type === "remove") {
        typeLabel = "ลด";
      }
      newSwal.fire({
        title: `ยืนยันทำรายการ${typeLabel}เครดิต`,
        icon: "info",
        html: `ยูสเซอร์: <b>${member?.username.replace(/\D/g, "")}</b><br/>ชื่อสกุล: <b>${
          member?.first_name
        } ${member?.last_name}</b><br/>จำนวน: <b>${formatter.format(value).toString()}</b>`,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
        preConfirm: () => {
          Swal.fire({
            title: "กำลังทำรายการ",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          handleConfirm();
        },
      });
    }
  };

  console.log("creditForm-type", type);

  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12} lg={6}>
        <Card sx={{ border: 2 }}>
          <SuiBox p={3} mb={1} textAlign="center">
            <SuiBox p={3} lineHeight={1} display="flex" alignItems="center">
              <SuiBox component="img" src={contrast} width="56px" />
              <SuiTypography ml={2} variant="h3" textTransform="capitalize" fontWeight="bold">
                ทำรายการ เพิ่ม/ลด เครดิต กระเป๋าหลัก
              </SuiTypography>
            </SuiBox>
          </SuiBox>
          <Divider />
          <Grid container justifyContent="center" className="h-100" mb={3}>
            <Grid item xs={12} lg={8}>
              <MemberCheck onSetMember={(row) => handleSetMemberBalance(row)} />
              {member && member?.member_uid && (
                <SuiBox mt={1}>
                  <SuiBox mt={1} display="flex" justifyContent="center" alignItems="center">
                    <SuiTypography component="label" variant="caption">
                      ยูส:
                    </SuiTypography>
                    <SuiTypography ml={2} variant="h5" fontWeight="medium">
                      {member?.username.replace(/\D/g, "")}
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox mt={1} display="flex" justifyContent="center" alignItems="center">
                    <SuiTypography component="label" variant="caption">
                      เครดิตปัจจุบัน:
                    </SuiTypography>
                    <SuiTypography ml={2} variant="h5" fontWeight="medium">
                      {balance}
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={type}
                      onChange={(t) => setType(t.target.value)}
                    >
                      <FormControlLabel value="add" control={<Radio />} label="เพิ่มเครดิต" />
                      <FormControlLabel value="remove" control={<Radio />} label="ลดเครดิต" />
                    </RadioGroup>
                  </SuiBox>
                  <SuiBox>
                    <SuiTypography component="label" variant="caption">
                      จำนวนที่ต้องการเพิ่ม/ลด
                    </SuiTypography>
                    <SuiInput
                      value={value}
                      onChange={(event) => handleSetValue(event)}
                      placeholder="จำนวน"
                    />
                  </SuiBox>
                  <SuiBox>
                    <SuiTypography component="label" variant="caption">
                      เหตุผลการเพิ่ม/ลดเครดิต
                    </SuiTypography>
                    <SuiSelect
                      fullWidth
                      placeholder="เลือกเหตุผล"
                      menuPlacement="top"
                      defaultValue={reason}
                      options={optios}
                      onChange={(option) => {
                        setReason(option);
                      }}
                    />
                    {reason?.value === "other" && (
                      <SuiBox>
                        <SuiTypography component="label" variant="caption">
                          ระบุเหตุผล
                        </SuiTypography>
                        <SuiInput
                          type="text"
                          value={remark ?? ""}
                          onChange={(event) => {
                            handleChange(event.target.value);
                            setError(undefined);
                          }}
                          placeholder="เหตุผล"
                        />
                      </SuiBox>
                    )}
                  </SuiBox>
                  <SuiBox mt={1} display="flex" justifyContent="space-between">
                    <SuiBox>
                      <h6
                        style={{
                          fontSize: ".8em",
                          color: "red",
                          textAlign: "center",
                          fontWeight: 400,
                          transition: ".2s all",
                        }}
                      >
                        {error}
                      </h6>
                    </SuiBox>
                    <SuiBox>
                      <SuiButton
                        onClick={() => handleCheck()}
                        variant="gradient"
                        buttonColor="dark"
                      >
                        ยืนยัน
                      </SuiButton>
                    </SuiBox>
                  </SuiBox>
                </SuiBox>
              )}
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}

export default CreditForm;
