/* eslint-disable */

import { useState, useEffect } from "react";
import Card from "@mui/material/Card";

import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import breakpoints from "assets/theme/base/breakpoints";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import contrast from "assets/png/contrast.png";
import CreditForm from "./credit-form";
import { Divider } from "@mui/material";
import ImportCredit from "./import-credit";

function CreditFormPage() {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  const handleSetTab = (event, newTabValue) => setTabValue(newTabValue);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.md
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox pt={6} pb={3}>
        <Card>
          <SuiBox display="flex" justifyContent="space-between" alignItems="center" py={6} px={2}>
            <SuiBox p={3} lineHeight={1} display="flex" alignItems="center">
              <SuiBox component="img" src={contrast} width="56px" />
              <SuiTypography ml={2} variant="h3" textTransform="capitalize" fontWeight="bold">
                ทำรายการ เพิ่ม/ลด เครดิต
              </SuiTypography>
            </SuiBox>
            <SuiBox display="flex" width="60%">
              <SuiBox width="100%">
                <AppBar position="static">
                  <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTab}>
                    <Tab label="เพิ่ม/ลด รายยูสเซอร์" />
                    <Tab label="Import File" />
                  </Tabs>
                </AppBar>
              </SuiBox>
            </SuiBox>
          </SuiBox>
          <Divider />
          <SuiBox py={6}>
            {tabValue === 0 && <CreditForm />}
            {tabValue === 1 && <ImportCredit />}
          </SuiBox>
        </Card>
      </SuiBox>
    </DashboardLayout>
  );
}

export default CreditFormPage;
